import { useState } from "react";
import Logo from "../assets/images/logo.jpg";
import Kid from "../assets/images/kid.jpg";

import Work1 from "../assets/images/work/1.jpg";
import Work2 from "../assets/images/work/2.jpg";
import Work3 from "../assets/images/work/3.jpg";

import SliderImage1 from "../assets/images/Slider Images/1.JPG";
import SliderImage2 from "../assets/images/Slider Images/2.JPG";
import SliderImage3 from "../assets/images/Slider Images/3.JPG";
import SliderImage4 from "../assets/images/Slider Images/4.JPG";
import SliderImage5 from "../assets/images/Slider Images/5.JPG";
import SliderImage6 from "../assets/images/Slider Images/6.JPG";
import SliderImage7 from "../assets/images/Slider Images/7.JPG";
import SliderImage8 from "../assets/images/Slider Images/8.JPG";

import AimImage1 from "../assets/images/Aims/1.JPG";
import AimImage2 from "../assets/images/Aims/2.JPG";
import AimImage3 from "../assets/images/Aims/3.JPG";
import AimImage4 from "../assets/images/Aims/4.JPG";

import FloodReliefImage1 from "../assets/images/Projects/1.JPG";
import FloodReliefImage2 from "../assets/images/Projects/2.JPG";
import FloodReliefImage3 from "../assets/images/Projects/3.JPG";
import FloodReliefImage4 from "../assets/images/Projects/4.JPG";

import Blanket_Appeal from "../assets/images/work/blanket_appeal.jpg";
import Girls_Edu from "../assets/images/work/girls_edu.jpg";
import RO_Plant from "../assets/images/work/ro_plant.jpg";

import SurgeryProgress from "../assets/images/Projects/Surgery_Progress.png";

import { FaFacebookF, FaInstagram } from "react-icons/fa";

import { Navigation, Pagination, Scrollbar, A11y, Autoplay } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";

import {
  HiMenu,
  HiX,
  HiHome,
  HiUsers,
  HiChartPie,
  HiMail,
  HiUserGroup,
  HiCreditCard,
} from "react-icons/hi";
import { useEffect } from "react";

const Home = () => {
  const [sidebarActive, setSidebarActive] = useState(true);

  const [activeProject, setActiveProject] = useState("project-1");

  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
  };

  function toggleSidebar() {
    const sidebar = document.getElementById("sidebar");

    if (sidebarActive) {
      setSidebarActive(false);
      sidebar.style.transform = "translateX(0)";
      sidebar.style.visibility = "visible";
    } else {
      setSidebarActive(true);
      sidebar.style.transform = "translateX(100%)";
      sidebar.style.visibility = "hidden";
    }
  }

  useEffect(() => {
    document.getElementsByClassName("swiper-button-next")[0].style.color =
      "#fff";

    document.getElementsByClassName("swiper-button-prev")[0].style.color =
      "#fff";
  }, []);

  return (
    <div className='w-screen min-h-screen overflow-x-hidden bg-white'>
      {/* Navbar */}
      <div className='fixed z-50 flex w-full p-3 bg-white'>
        <img src={Logo} className='w-32 h-auto' alt='' />

        <div className='ml-auto flex space-x-5 items-center p-3'>
          {/* Paypal */}
          <form
            action='https://www.paypal.com/donate'
            method='post'
            target='_top'
          >
            <input
              type='hidden'
              name='hosted_button_id'
              value='DJ7K33CCE5L3C'
            />
            <input
              type='image'
              src='https://www.paypalobjects.com/en_US/GB/i/btn/btn_donateCC_LG.gif'
              border='0'
              name='submit'
              title='PayPal - The safer, easier way to pay online!'
              alt='Donate with PayPal button'
            />
            <img
              alt=''
              border='0'
              src='https://www.paypal.com/en_GB/i/scr/pixel.gif'
              width='1'
              height='1'
            />
          </form>

          <button
            onClick={toggleSidebar}
            className='p-1 text-2xl rounded-full transition-all duration-700 active:scale-95'
          >
            <HiMenu />
          </button>
        </div>
      </div>

      {/* Sidebar */}
      <div
        id='sidebar'
        className='fixed z-50 w-72 h-screen bg-white p-5 right-0 top-0 shadow-lg transition-all duration-500 translate-x-full invisible'
      >
        <div className='w-full flex'>
          <div className='ml-auto'>
            <button
              onClick={toggleSidebar}
              className='p-1 text-xl rounded-full'
            >
              <HiX />
            </button>
          </div>
        </div>
        <br />
        <div className='w-full grid grid-cols-1 gap-1 p-5'>
          <a
            href='#home'
            className='p-1 md:p-2.5 w-full text-left rounded-md hover:text-green-600'
            onClick={toggleSidebar}
          >
            Home
          </a>

          <br />

          <a
            href='#about-us'
            className='p-1 md:p-2.5 w-full text-left rounded-md hover:text-green-600'
            onClick={toggleSidebar}
          >
            About Us
          </a>

          <br />

          <a
            href='#our-aims'
            className='p-1 md:p-2.5 w-full text-left rounded-md hover:text-green-600'
            onClick={toggleSidebar}
          >
            Our Aims
          </a>

          <br />

          <a
            href='#our-beneficiaries'
            className='p-1 md:p-2.5 w-full text-left rounded-md hover:text-green-600'
            onClick={toggleSidebar}
          >
            Our Beneficiaries
          </a>

          <br />

          <a
            href='#our-projects'
            className='p-1 md:p-2.5 w-full text-left rounded-md hover:text-green-600'
            onClick={toggleSidebar}
          >
            Our Projects
          </a>

          <br />

          <a
            href='#get-involved'
            className='p-1 md:p-2.5 w-full text-left rounded-md hover:text-green-600'
            onClick={toggleSidebar}
          >
            Get Involved
          </a>
        </div>
      </div>

      {/* Slider */}
      <div id='home' className='mt-40'>
        <Swiper
          // install Swiper modules
          modules={[Navigation, Pagination, Scrollbar, A11y, Autoplay]}
          spaceBetween={50}
          slidesPerView={1}
          loop={true}
          autoplay={{
            delay: 2500,
            disableOnInteraction: false,
          }}
          navigation
        >
          <SwiperSlide>
            <img src={SliderImage1} className='h-72 w-screen md:h-screen' />
          </SwiperSlide>
          <SwiperSlide>
            <img src={SliderImage2} className='h-72 w-screen md:h-screen' />
          </SwiperSlide>
          <SwiperSlide>
            <img src={SliderImage3} className='h-72 w-screen md:h-screen' />
          </SwiperSlide>
          <SwiperSlide>
            <img src={SliderImage4} className='h-72 w-screen md:h-screen' />
          </SwiperSlide>
          <SwiperSlide>
            <img src={SliderImage5} className='h-72 w-screen md:h-screen' />
          </SwiperSlide>
          <SwiperSlide>
            <img src={SliderImage6} className='h-72 w-screen md:h-screen' />
          </SwiperSlide>
          <SwiperSlide>
            <img src={SliderImage7} className='h-72 w-screen md:h-screen' />
          </SwiperSlide>
          <SwiperSlide>
            <img src={SliderImage8} className='h-72 w-screen md:h-screen' />
          </SwiperSlide>
        </Swiper>
      </div>

      <br />

      {/* About Us */}
      <div
        id='about-us'
        className='grid grid-cols-1 md:grid-cols-2 gap-4 p-5 md:p-10'
      >
        <div className='grid grid-cols-2 gap-4'>
          <img src={Work1} className='row-span-2 rounded-lg mt-5' />

          <img src={Work2} className='rounded-md' />

          <img src={Work3} className='rounded-md' />
        </div>
        <div className='w-full h-full flex flex-col justify-center items-start p-5'>
          <h3 className='font-semibold text-5xl border-b-4 border-green-500'>
            About Us
          </h3>

          <br />

          <p className='text-left'>
            Farishta Foundation UK is a non-profit organization working since
            2022 with a vision of improving the lives of children & families in
            Tharparkar and Karachi by collaborating with different donors,
            volunteers and corporate organizations. We work in partnership with
            Children and Families Welfare Organization.
          </p>
        </div>
      </div>

      <br />

      {/* Our Aims */}
      <div
        id='our-aims'
        className='grid grid-cols-1 md:grid-cols-4 gap-4 bg-green-600 p-5 py-10'
      >
        <h3 className='font-semibold text-5xl text-white text-center mb-5 md:hidden'>
          Our Aims
        </h3>

        <div className='w-full grid grid-cols-2 md:grid-cols-1 gap-4'>
          <img
            src={AimImage1}
            className='rounded-l-full rounded-t-full shadow-lg'
          />

          <img
            src={AimImage2}
            className='rounded-r-full rounded-t-full shadow-lg md:hidden'
          />

          <img
            src={AimImage3}
            className='rounded-l-full rounded-b-full shadow-lg'
          />

          <img
            src={AimImage4}
            className='rounded-r-full rounded-b-full shadow-lg md:hidden'
          />
        </div>

        <div className='md:col-span-2 flex flex-col w-full h-full justify-center items-center p-3 md:p-5'>
          <h3 className='font-semibold text-5xl text-white hidden md:block'>
            Our Aims
          </h3>

          <br className='hidden md:block' />

          <p className='text-center text-white'>
            <span className='font-semibold text-lg text-gray-100'>
              As a foundation we aim to work towards providing: <br /> 1- Free
              Healthcare for Women <br /> 2- Free Education for Children <br />{" "}
              3- Free Clean Drinking Water for the Communities <br /> 4- Support
              with Environmental challenges within Communities <br />
            </span>{" "}
            <br /> There are some other additional interventions that are also
            being done by the organizations on timely basis that includes
            provision of food, clothing, winter blankets, disaster and flood
            relief and management, low-cost housing and school stationary
            supplies etc. These are need-based mediations that are conducted by
            our volunteers.
          </p>
        </div>

        <div className='w-full hidden md:grid grid-cols-1 gap-4'>
          <img
            src={AimImage2}
            className='rounded-r-full rounded-t-full shadow-lg'
          />

          <img
            src={AimImage4}
            className='rounded-r-full rounded-b-full shadow-lg'
          />
        </div>
      </div>

      <br />

      {/* Our Beneficiaries */}
      <div
        id='our-beneficiaries'
        className='grid grid-cols-1 md:grid-cols-2 gap-4 p-5 md:p-10'
      >
        <div className='w-full h-full flex flex-col justify-center items-start p-5'>
          <h3 className='font-semibold text-5xl border-b-4 border-green-500'>
            Our Beneficiaries
          </h3>

          <br />

          <p className='text-left'>
            Over 95% of our beneficiaries are disadvantaged young children and
            families. They all live below the poverty line and are deprived of
            food, clean clothing and clean drinking water. The locations are in
            the middle of Thar Desert where the temperature in summers rises
            close to 50 degree C (Regions/Tehsils: Kaloi, Mithi & Diplo). The
            locations are in the old town of Karachi (Usmanabad, Ram Swami,
            Muhajir Camp, Landhi, Korangi, Malir and Surjani Town)
          </p>
        </div>

        <div className='p-5'>
          <Swiper
            // install Swiper modules
            modules={[Scrollbar, A11y, Autoplay]}
            spaceBetween={10}
            slidesPerView={1}
            loop={true}
            autoplay={{
              delay: 2000,
              disableOnInteraction: false,
            }}
          >
            <SwiperSlide>
              <img src={AimImage1} className='w-full mx-auto rounded-md' />
            </SwiperSlide>

            <SwiperSlide>
              <img src={AimImage2} className='w-full mx-auto rounded-md' />
            </SwiperSlide>

            <SwiperSlide>
              <img src={AimImage3} className='w-full mx-auto rounded-md' />
            </SwiperSlide>

            <SwiperSlide>
              <img src={AimImage4} className='w-full mx-auto rounded-md' />
            </SwiperSlide>
          </Swiper>
        </div>
      </div>

      <br />

      {/* Our Projects */}

      <div id='our-projects' className='w-full p-5'>
        <h3 className='font-semibold w-auto max-w-min mx-auto text-5xl border-b-4 border-green-500'>
          Projects
        </h3>

        <br />

        <div className='w-full flex overflow-auto space-x-3 px-2 md:px-5'>
          <button
            onClick={() => {
              setActiveProject("project-1");
            }}
            className='p-1 md:p-2.5 w-auto flex-none bg-gray-200 rounded-md hover:text-green-600'
          >
            Flood Relief Projects 2022
          </button>

          <button
            onClick={() => {
              setActiveProject("project-2");
            }}
            className='p-1 md:p-2.5 w-auto flex-none bg-gray-200 rounded-md hover:text-green-600'
          >
            Thar Drinking Water Project
          </button>

          <button
            onClick={() => {
              setActiveProject("project-3");
            }}
            className='p-1 md:p-2.5 w-auto flex-none bg-gray-200 rounded-md hover:text-green-600'
          >
            Thar Free Clinic Project
          </button>

          <button
            onClick={() => {
              setActiveProject("project-4");
            }}
            className='p-1 md:p-2.5 w-auto flex-none bg-gray-200 rounded-md hover:text-green-600'
          >
            Primary and Secondary School Improvement Project
          </button>

          <button
            onClick={() => {
              setActiveProject("project-5");
            }}
            className='p-1 md:p-2.5 w-auto flex-none bg-gray-200 rounded-md hover:text-green-600'
          >
            Thar Desert Green Project
          </button>
        </div>

        <br />

        <div className='grid grid-cols-1 md:grid-cols-2 gap-4 md:p-5'>
          {activeProject === "project-1" ? (
            <div>
              <h3 className='font-bold font-serif text-3xl'>
                Flood Relief Projects 2022
              </h3>

              <h5 className='font-semibold text-gray-800'>
                Our 5 RELIEF Programs for 5000+ displaced families in Thar
              </h5>

              <br />

              <div>
                <h6 className='font-semibold uppercase'>1- Thar Free Clinic</h6>
                <p className='font-light'>
                  Where we give Free treatment with medicine to 415 patients in
                  2 days.
                </p>
                <h6 className='font-semibold'>How you can help ?</h6>
                <p className='font-light'>
                  By donating to purchase medicine £150/$150.
                </p>
              </div>

              <br />

              <div>
                <h6 className='font-semibold uppercase'>
                  {" "}
                  2-Aata Flour 10 kg for a family
                </h6>
                <p className='font-light'>
                  Distributed Flour Bags to 2000+ displaced families.
                </p>
                <h6 className='font-semibold'>How you can help ?</h6>
                <p className='font-light'>
                  Donate from £5/$5 to support one family (enough Flour to
                  support a family for 10 days)
                </p>
              </div>

              <br />

              <div>
                <h6 className='font-semibold uppercase'>3-Daily Hot Food</h6>
                <p className='font-light'>
                  10 Deghs (600packs) cooked, packed & delivered feeding 1,000
                  people daily.
                </p>
                <h6 className='font-semibold'>How you can help ?</h6>
                <p className='font-light'>
                  Donate for Each Degh £30/$30, Costs £300/$300 daily to provide
                  ready-to-eat hot food 1,000 people
                </p>
              </div>

              <br />

              <div>
                <h6 className='font-semibold uppercase'>
                  4-Goat Meats with Masala
                </h6>
                <p className='font-light'>
                  Daily 100 different families are getting sacrificed & packed
                  goat meat to help them fight malnutrition.
                </p>
                <h6 className='font-semibold'>How you can help ?</h6>
                <p className='font-light'>
                  Donate the program costing us £250/$250 daily for 100
                  families. Over 10 days 1,000 families will regain their
                  strength.
                </p>
              </div>

              <br />

              <div>
                <h6 className='font-semibold uppercase'>
                  5- THAR FREE CLEAN DRINKING WATER
                </h6>
                <p className='font-light'>
                  Providing Drinking Water by Water Tanker to multiple
                  settlements absolutely free.
                </p>
                <h6 className='font-semibold'>How you can help ?</h6>
                <p className='font-light'>
                  Donate to purchase Diesel Fuel £200/$200 (Approx. 175 litres)
                  per week.
                </p>
              </div>

              <br />

              <h5 className='font-semibold text-gray-800'>Donate Now</h5>

              <br />

              <div className='w-full grid grid-cols-2 gap-4'>
                <p>
                  Bank Transfer: Farishta Foundation UK <br /> The Co-operative
                  Bank <br /> IBAN: GB98 CPBK 0892 9967 2600 87 <br /> Sort
                  Code: 08-92-99 <br /> A/c. No. 6726 0087
                </p>

                {/* Paypal */}
                <form
                  action='https://www.paypal.com/donate'
                  method='post'
                  target='_top'
                  className='flex w-full h-full justify-center items-center'
                >
                  <input
                    type='hidden'
                    name='hosted_button_id'
                    value='DJ7K33CCE5L3C'
                  />
                  <input
                    type='image'
                    src='https://www.paypalobjects.com/en_US/GB/i/btn/btn_donateCC_LG.gif'
                    border='0'
                    name='submit'
                    title='PayPal - The safer, easier way to pay online!'
                    alt='Donate with PayPal button'
                  />
                  <img
                    alt=''
                    border='0'
                    src='https://www.paypal.com/en_GB/i/scr/pixel.gif'
                    width='1'
                    height='1'
                  />
                </form>
              </div>
            </div>
          ) : (
            <></>
          )}

          {activeProject === "project-2" ? (
            <div>
              <h3 className='font-bold font-serif text-3xl'>
                Thar Drinking Water Project
              </h3>

              <br />

              <p>
                With the help of Thar Drinking Water Project CFWO and Farishta
                Foundation are supplying quality drinking water in different
                villages of Tehsil: Kaloi, Diplo and Mithi in District
                Tharparkar. From 750 feet deep bore under the ground water is
                extracted through a 15 kilowatts pump and stored into untreated
                water reservoir. From untreated water reservoir the water is
                drawn through a feed pump and is taken through a four level
                series of filtration system. The final step is Reverse Osmosis
                filtration tubes that finally dispenses pure clean drinking
                water.In the longer run the organizations aim to provide clean
                drinking water to more 225 villages in Tharparkar. To achieve
                this, there is a need for another water supply truck and once
                that is made available the project will expand the supply of
                water. Overall, 14,000 people are benefiting from this project
                EVERY DAY. 4.2 million litres of clean water filtered, processed
                and transported (ABSOLUTELY FREE) to 140 villages. 140,000
                serving of at-least 1 litre clean water per person is taking
                place daily.
              </p>

              <br />

              <h5 className='font-semibold text-gray-800'>Donate Now</h5>

              <br />

              <div className='w-full grid grid-cols-2 gap-4'>
                <p>
                  Bank Transfer: Farishta Foundation UK <br /> The Co-operative
                  Bank <br /> IBAN: GB98 CPBK 0892 9967 2600 87 <br /> Sort
                  Code: 08-92-99 <br /> A/c. No. 6726 0087
                </p>

                {/* Paypal */}
                <form
                  action='https://www.paypal.com/donate'
                  method='post'
                  target='_top'
                  className='flex w-full h-full justify-center items-center'
                >
                  <input
                    type='hidden'
                    name='hosted_button_id'
                    value='DJ7K33CCE5L3C'
                  />
                  <input
                    type='image'
                    src='https://www.paypalobjects.com/en_US/GB/i/btn/btn_donateCC_LG.gif'
                    border='0'
                    name='submit'
                    title='PayPal - The safer, easier way to pay online!'
                    alt='Donate with PayPal button'
                  />
                  <img
                    alt=''
                    border='0'
                    src='https://www.paypal.com/en_GB/i/scr/pixel.gif'
                    width='1'
                    height='1'
                  />
                </form>
              </div>
            </div>
          ) : (
            <></>
          )}

          {activeProject === "project-3" ? (
            <div>
              <h3 className='font-bold font-serif text-3xl'>
                Thar Free Clinic Project
              </h3>

              <br />

              <p>
                Thar Free Health Clinic that addresses that above mentioned
                problem among the women and infants. Through this project we
                give free medication & basic treatment to patient who cannot
                afford it. The Free Clinic operates in a small village of
                Tharparkar called Ithado. It covers 60 villages in 40 mile
                radius. Patients from far flung areas visit and get themselves
                treated for numerous health issues such as: Gastric, Kidneys,
                various Infections, Dehydration and Heart problems in children
                and adults etc. This project also transport terminally ill
                patients to Karachi, give them place to stay and get them
                treatment/ surgery, meds, tests etc. with their connections with
                doctors & hospitals. A well-equipped clinic space serves and
                treat over 1000 patients monthly with free medication, health
                checkups. Since the start of the project here has been 48
                surgeries and procedures in Tharparkar.
              </p>

              <br />

              <img src={SurgeryProgress} className='w-full' />

              <br />

              <p>
                Since 2018, work is in progress on the creation of an organic
                capsule from an indigenous plant called “Moringa Oleifera” also
                known as the Miracle Tree. These super-food supplements will
                provide essential nutrients for the maintenance of life and for
                growth of infants and women in the villages. The organizations
                are working on the mass production of these supplements and they
                will be given at no cost to the locals from the Free Health
                Clinic.
              </p>

              <br />

              <p>
                In the longer run there will be 2 more of such clinics will be
                constructed and with the current clinic facility an extended
                facility for maternity will also be created. This initiative
                will also increase the incoming of the patients from far flung
                areas of Tharparkar. A proposal for the extension of the clinic
                for Maternity Health Care will be soon shared with the donors
                and then the work on for free maternal health care will also
                take place as part of the project.
              </p>

              <br />

              <h5 className='font-semibold text-gray-800'>Donate Now</h5>

              <br />

              <div className='w-full grid grid-cols-2 gap-4'>
                <p>
                  Bank Transfer: Farishta Foundation UK <br /> The Co-operative
                  Bank <br /> IBAN: GB98 CPBK 0892 9967 2600 87 <br /> Sort
                  Code: 08-92-99 <br /> A/c. No. 6726 0087
                </p>

                {/* Paypal */}
                <form
                  action='https://www.paypal.com/donate'
                  method='post'
                  target='_top'
                  className='flex w-full h-full justify-center items-center'
                >
                  <input
                    type='hidden'
                    name='hosted_button_id'
                    value='DJ7K33CCE5L3C'
                  />
                  <input
                    type='image'
                    src='https://www.paypalobjects.com/en_US/GB/i/btn/btn_donateCC_LG.gif'
                    border='0'
                    name='submit'
                    title='PayPal - The safer, easier way to pay online!'
                    alt='Donate with PayPal button'
                  />
                  <img
                    alt=''
                    border='0'
                    src='https://www.paypal.com/en_GB/i/scr/pixel.gif'
                    width='1'
                    height='1'
                  />
                </form>
              </div>
            </div>
          ) : (
            <></>
          )}

          {activeProject === "project-4" ? (
            <div>
              <h3 className='font-bold font-serif text-3xl'>
                Primary and Secondary School Improvement Project
              </h3>

              <br />

              <p>
                To address this problem the organizations have adopted two
                schools, one is the primary school and other one is a middle
                school in Kaloi village of Tharparkar. This project is focused
                on providing quality education to the enrolled students. Teams
                of educationalists and volunteers visit every quarter to conduct
                workshops with the students and teachers. Constant monitoring
                and mentoring on students and teachers is being done by the
                community volunteers. This project also focuses on computer
                skills of the students and teachers. Some workshops have been
                done to teach basic computer skills. However, in the longer more
                intensive workshops will be planned for the students and
                teachers. The organizations are in planning to construct a
                separate school for girls from class (1-8), with female teacher
                and staff. The reason for constructing this separate school is
                to remove the gender barrier within the community. After grade
                4, the villagers are not comfortable in sending their girls to
                school. Therefore, with this extension more girls will be
                willing to come to the school for quality education. Overall,
                there are 69 students enrolled in this school and are benefiting
                free education until middle school (grade 1-8). Continuous and
                as per need free stationary, trainings to teachers and students.
              </p>

              <br />

              <h5 className='font-semibold text-gray-800'>Donate Now</h5>

              <br />

              <div className='w-full grid grid-cols-2 gap-4'>
                <p>
                  Bank Transfer: Farishta Foundation UK <br /> The Co-operative
                  Bank <br /> IBAN: GB98 CPBK 0892 9967 2600 87 <br /> Sort
                  Code: 08-92-99 <br /> A/c. No. 6726 0087
                </p>

                {/* Paypal */}
                <form
                  action='https://www.paypal.com/donate'
                  method='post'
                  target='_top'
                  className='flex w-full h-full justify-center items-center'
                >
                  <input
                    type='hidden'
                    name='hosted_button_id'
                    value='DJ7K33CCE5L3C'
                  />
                  <input
                    type='image'
                    src='https://www.paypalobjects.com/en_US/GB/i/btn/btn_donateCC_LG.gif'
                    border='0'
                    name='submit'
                    title='PayPal - The safer, easier way to pay online!'
                    alt='Donate with PayPal button'
                  />
                  <img
                    alt=''
                    border='0'
                    src='https://www.paypal.com/en_GB/i/scr/pixel.gif'
                    width='1'
                    height='1'
                  />
                </form>
              </div>
            </div>
          ) : (
            <></>
          )}

          {activeProject === "project-5" ? (
            <div>
              <h3 className='font-bold font-serif text-3xl'>
                Thar Desert Green Project
              </h3>

              <br />

              <p>
                Since 2018 the Thar Desert Green Project is working with
                different type of indigenous plants & trees that can thrive in
                the harsh desert climate. The organizations have also joint
                forces with Engro/SECMC Thar Coal and planted over 6,500 tree
                saplings of 7 different indigenous species on barren land
                successfully. They are also in research partnership with Karachi
                University to grow plants from discarded brackish water from the
                RO Plant. By the end of 2023, this project aims to grow 35,000
                trees. The major purpose of this project is to decrease the
                temperature reduce to 2 degree centigrade. This project will
                also trigger an eco-system where local wild life including birds
                and insects will thrive in favorable climatic conditions.
                Overall, 8500 trees are currently under the care of the
                organizations and partnered villages which provides pleasant
                environment to the surroundings.
              </p>

              <br />

              <h5 className='font-semibold text-gray-800'>Donate Now</h5>

              <br />

              <div className='w-full grid grid-cols-2 gap-4'>
                <p>
                  Bank Transfer: Farishta Foundation UK <br /> The Co-operative
                  Bank <br /> IBAN: GB98 CPBK 0892 9967 2600 87 <br /> Sort
                  Code: 08-92-99 <br /> A/c. No. 6726 0087
                </p>

                {/* Paypal */}
                <form
                  action='https://www.paypal.com/donate'
                  method='post'
                  target='_top'
                  className='flex w-full h-full justify-center items-center'
                >
                  <input
                    type='hidden'
                    name='hosted_button_id'
                    value='DJ7K33CCE5L3C'
                  />
                  <input
                    type='image'
                    src='https://www.paypalobjects.com/en_US/GB/i/btn/btn_donateCC_LG.gif'
                    border='0'
                    name='submit'
                    title='PayPal - The safer, easier way to pay online!'
                    alt='Donate with PayPal button'
                  />
                  <img
                    alt=''
                    border='0'
                    src='https://www.paypal.com/en_GB/i/scr/pixel.gif'
                    width='1'
                    height='1'
                  />
                </form>
              </div>
            </div>
          ) : (
            <></>
          )}

          <div className='grid grid-cols-2 gap-4'>
            <img src={Work1} className='w-full rounded-md row-span-2' alt='' />

            <img src={Blanket_Appeal} className='w-full rounded-md' alt='' />

            <img src={RO_Plant} className='w-full rounded-md' alt='' />

            <img src={FloodReliefImage4} className='w-full rounded-md' alt='' />

            <img
              src={FloodReliefImage2}
              className='w-full rounded-md row-span-2'
              alt=''
            />

            <img src={FloodReliefImage3} className='w-full rounded-md' alt='' />

            <img src={FloodReliefImage1} className='w-full rounded-md' alt='' />

            <img src={AimImage1} className='w-full rounded-md' alt='' />
          </div>
        </div>
      </div>

      <br />

      {/* Get Involved */}
      <div
        id='get-involved'
        className='w-full grid grid-cols-1 md:grid-cols-3 gap-4 p-10'
      >
        <div className='col-span-2'>
          <h3 className='font-semibold w-auto max-w-max mx-auto text-5xl border-b-4 border-green-500'>
            Get Involved
          </h3>

          <br />

          <p className='text-center md:text-left'>
            We envision people/organizations to collaborate with us by
            Volunteering in different capacities such as:
          </p>

          <div className='p-5 px-10'>
            <ol className='list-decimal' start={1}>
              <li>
                By bringing their own social service initiatives/research
                projects aligned with our vision to our beneficiaries.
              </li>
              <li>
                By utilizing their Corporate Social Responsibility Funding with
                our projects.
              </li>
              <li>
                For university students they can do their thesis/final year
                projects with our beneficiaries such as: movie making,
                teaching/training on different themes, developing and conducting
                workshops, conducting research for projects improvement etc.
              </li>
              <li>
                By partnering/collaborating with us by donating different
                products such as uniforms, reading books for primary and middle
                schools, stationary, blankets, warm clothes, food items, masks,
                sanitizers, wheel chairs, medical supplies sanitary napkins etc.
              </li>
            </ol>
          </div>
        </div>

        <div className='w-full flex flex-col justify-center items-center space-y-4'>
          <p className='text-center'>
            Bank Transfer: Farishta Foundation UK <br /> The Co-operative Bank{" "}
            <br />
            IBAN: GB98 CPBK 0892 9967 2600 87 <br /> Sort Code: 08-92-99 <br />{" "}
            A/c. No. 6726 0087
          </p>

          {/* Paypal */}
          <form
            action='https://www.paypal.com/donate'
            method='post'
            target='_top'
          >
            <input
              type='hidden'
              name='hosted_button_id'
              value='DJ7K33CCE5L3C'
            />
            <input
              type='image'
              src='https://www.paypalobjects.com/en_US/GB/i/btn/btn_donateCC_LG.gif'
              border='0'
              name='submit'
              title='PayPal - The safer, easier way to pay online!'
              alt='Donate with PayPal button'
            />
            <img
              alt=''
              border='0'
              src='https://www.paypal.com/en_GB/i/scr/pixel.gif'
              width='1'
              height='1'
            />
          </form>
        </div>
      </div>

      <br />

      {/* Footer */}
      <div className='p-5 w-full grid grid-cols-2 md:grid-cols-4 gap-4'>
        <div className='w-full h-full md:flex md:justify-center md:items-center'>
          <img src={Logo} />
        </div>

        <div className='w-full h-full md:flex md:flex-col md:justify-center'>
          <h6 className='font-semibold text-base'>Quick Links</h6>

          <br />

          <a
            href='#home'
            className='p-1 w-full text-sm text-left hover:text-green-600'
          >
            Home
          </a>

          <br className='md:hidden' />

          <a
            href='#about-us'
            className='p-1 w-full text-sm text-left hover:text-green-600'
          >
            About Us
          </a>

          <br className='md:hidden' />

          <a
            href='#our-aims'
            className='p-1 w-full text-sm text-left hover:text-green-600'
          >
            Our Aims
          </a>

          <br className='md:hidden' />

          <a
            href='#our-beneficiaries'
            className='p-1 w-full text-sm text-left hover:text-green-600'
          >
            Our Beneficiaries
          </a>

          <br className='md:hidden' />

          <a
            href='#our-projects'
            className='p-1 w-full text-sm text-left hover:text-green-600'
          >
            Our Projects
          </a>

          <br className='md:hidden' />

          <a
            href='#get-involved'
            className='p-1 w-full text-sm text-left hover:text-green-600'
          >
            Get Involved
          </a>

          <div className='flex w-full space-x-5 mt-5'>
            <a
              href='https://www.instagram.com/farishtafoundation_uk/'
              className='p-2 max-w-min rounded-full bg-gray-200'
            >
              <FaInstagram />
            </a>

            <a
              href='https://www.facebook.com/farishtafoundationuk'
              className='p-2 max-w-min rounded-full bg-gray-200'
            >
              <FaFacebookF />
            </a>
          </div>
        </div>

        <div className='col-span-2 md:pr-10 w-full'>
          <h4 className='font-semibold text-lg'>Contact Us</h4>

          <br />

          <p className='text-sm'>
            <span className='font-semibold'>Email: </span>{" "}
            info@farishtafoundation.org.uk <br />
            <span className='font-semibold'>Phone No: </span> +44 333 772 2552
          </p>

          <br />

          <form onSubmit={(e) => e.preventDefault()}>
            <input
              type='text'
              placeholder='Subject'
              className='p-3 border w-full'
            />

            <br />
            <br />

            <textarea
              className='w-full border p-3'
              cols='30'
              rows='5'
              placeholder='Message'
            ></textarea>

            <br />

            <button className='p-2.5 w-full bg-green-600 text-white'>
              Submit
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Home;
